html,body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

body {
  background: #fff;
  color: #000;
  height: auto;
  min-height: 100%;
  overflow: hidden;
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(255,255,255); 
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(184, 222, 181, 0.4) 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.8;
  margin: 1rem 0;
  color: rgb(26, 62, 145);
}

h1 {
  line-height: 1.3;
  font-size: 5vw;
  margin: 1rem 0;
}

h2 {
  font-size: 4vw;
  margin: 1rem 0;
}

h3 {
  font-size: 2vw;
  font-weight: bold;
}

.fade-in-message {
  animation: fadeIn 1s;
}
.fade-in-logo-icon {
  animation: fadeIn 4s;
}
.fade-in-logo-text {
  animation: fadeIn 3s;
}
.fade-in-cta {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
